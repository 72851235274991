import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, ListGroup } from 'react-bootstrap';
import styles from '../pa_revisao.module.scss';
import SelectInput from '../../../Misc/SelectInput/SelectInput';
import Tabela from '../../../Misc/Tabela/Tabela';
import { toast } from 'react-toastify';
import MaterialIcon from 'material-icons-react';
import { usePARevisao } from '../pa_revisao_context';
import ListarImagemPreview from '../../../Misc/ListarImagens/listar_imagens_preview';
import { isNullish } from '../../../../utils/misc_utils';

const PARevisaoDirecionamento = () => {
  const { referenciaSelecionada, modalDirecionamento, setModalDirecionamento, listaDefeitos, listaStatusDefeito, listaDirecionamentos, listaLocalizacaoDefeito, gradeRevisao, setGradeRevisao, direcionamentoRevisao, setDirecionamentoRevisao, handleRemoverDirecionamento, quantidadeTotalDirecionada, precoTagDivergente, composicaoTagDivergente, abrirModalImagens } = usePARevisao();

  const [formObj, setFormObj] = useState({
    grade: null, defeitos: [], status_defeito: null, localizacao_defeito: [], direcionamento: null, quantidade: null, imagens: []
  });
  const [defeitoSelecionado, setDefeitoSelecionado] = useState(null);
  const [locDefeitoSelecionado, setLocDefeitoSelecionado] = useState(null);

  const direcionamento_columns = [
    {
      cell: (row, index) => {
        return (
          <div onClick={() => handleRemoverDirecionamento(row, index)}>
            <MaterialIcon
              icon={'delete'}
              size={20}
              color='#BB271A'
            />
          </div>
        )
      }, width: '60px'
    },
    {
      name: '',
      cell: (row, index) => {
        return (
          <div onClick={() => abrirModalImagens(index)}>
            <MaterialIcon
              icon="photo_library"
              size={20}
            />
          </div>
        )
      }, width: '60px'
    },
    { name: 'Tamanho', selector: row => row.grade.tamanho, width: '100px' },
    { name: 'Quantidade', selector: row => row.quantidade, width: '100px' },
    { name: 'Status', selector: row => row.status_defeito.label, width: '160px' },
    { name: 'Defeitos', selector: row => row.defeitos.map(item => item.label).join(' | ') },
    { name: 'Loc. Defeito', selector: row => row.localizacao_defeito.map(item => item.label).join(' | ') },
    {
      name: 'Direcionamento',
      selector: row => {
        return composicaoTagDivergente.key || precoTagDivergente.key ? '43 - DEVOLUÇÃO (REVISÃO)' : row.direcionamento.label
      }
    }
  ];

  const handleSetFormObj = (field, value) => {
    if (field === 'grade') {
      formObj.quantidade = 0;
      formObj.defeitos = [];
      formObj.localizacao_defeito = [];
      formObj.status_defeito = null;
      formObj.direcionamento = null;
    }
    setFormObj({ ...formObj, [field]: value });
  }

  const handleSetFormObjStatusDefeito = (opt) => {
    setFormObj({
      ...formObj,
      status_defeito: opt,
      direcionamento: opt?.value === 8 ? { label: '43 - DEVOLUÇÃO (REVISÃO)', value: 43 } : null
    });
  }

  const handleSetFormObjQtde = (value) => {
    if (isNaN(value) || isNullish(value)) return setFormObj({ ...formObj, quantidade: 0 });
    value = parseInt(value, 10);

    if (parseInt(value) > formObj.grade.quantidade_aprovada_amostra) {
      value = formObj.grade.quantidade_aprovada_amostra;
    }
    if ((quantidadeTotalDirecionada + parseInt(value)) > referenciaSelecionada.qtde_pecas_amostra) {
      value = referenciaSelecionada.qtde_pecas_amostra - quantidadeTotalDirecionada;
    }
    setFormObj({ ...formObj, quantidade: parseInt(value) });
  }

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    formObj.imagens = [...formObj.imagens, ...files];
    if (formObj.imagens.length > 5) {
      formObj.imagens = formObj.imagens.splice(0, 5);
      toast.warning('Você só pode adicionar até cinco imagens por direcionamento!');
    }
    setFormObj({ ...formObj });
  }

  const compararDirecionamentos = () => {

  }

  const validarDirecionamento = () => {
    const { grade, defeitos, status_defeito, localizacao_defeito, direcionamento, quantidade } = formObj;
    const errs = [];
    try {
      if (isNullish(grade)) errs.push('tamanho');
      if (defeitos.length === 0) errs.push('defeitos');
      if (localizacao_defeito.length !== defeitos.length) errs.push('localizacao_defeito');
      if (isNullish(status_defeito)) errs.push('status_defeito');
      if (isNullish(direcionamento)) errs.push('direcionamento');
      if (isNullish(quantidade) || quantidade === 0) errs.push('quantidade');

      if (errs.length > 0) return toast.warning('Por favor selecione e/ou preencha todos os campos e adicione ao menos um defeito antes de inserir um direcionamento.');
      if (handleChecarCombinacaoDefeitoLocalizacao()) return toast.warning('Você inseriu exatamente a mesma combinação de defeitos/localizações de um direcionamento já inserido, por favor altere esse direcionamento ou remova o anterior.');

      handleInserirDirecionamento(formObj);
      setFormObj({ grade: null, defeitos: [], status_defeito: null, localizacao_defeito: [], direcionamento: null, quantidade: null, imagens: [] });
    } catch (e) {
      toast.error('Houve um problema ao inserir o direcionamento, por favor tente novamente.');
    }
  }

  const customFilter = (option, searchText) => {
    if (!searchText) return true;
    return option.label.toLowerCase().includes(searchText.toLowerCase());
  }

  const handleInserirDirecionamento = (formObj) => {
    const { grade, quantidade, defeitos, status_defeito, localizacao_defeito, direcionamento, imagens } = formObj;
    try {
      direcionamentoRevisao.push({ grade, quantidade: parseInt(quantidade), defeitos, status_defeito, localizacao_defeito, direcionamento, imagens });
      gradeRevisao[grade.index_grade].quantidade_aprovada -= parseInt(quantidade);
      gradeRevisao[grade.index_grade].quantidade_aprovada_amostra -= parseInt(quantidade);
      gradeRevisao[grade.index_grade].quantidade_defeituosa += parseInt(quantidade);
      gradeRevisao[grade.index_grade].sem_defeitos = false;
      setGradeRevisao([...gradeRevisao]);
      setDirecionamentoRevisao([...direcionamentoRevisao]);
    } catch (e) {
      throw 'Erro ao inserir direcionamento.\nPor favor tente novamente ou entre em contato com o suporte.';
    }
  }

  const closeModal = () => {
    setModalDirecionamento(false);
  }

  const removerDirecionamento = (index) => {
    formObj.defeitos.splice(index, 1);
    formObj.localizacao_defeito.splice(index, 1);
    setFormObj({ ...formObj });
  }

  const removerImagem = (index) => {
    formObj.imagens.splice(index, 1);
    setFormObj({
      ...formObj,
      imagens: [...formObj.imagens]
    });
  }

  const handleChecarCombinacaoDefeitoLocalizacao = () => {
    const combinacoesExistentes = new Set(
      direcionamentoRevisao.map(item =>
        [
          `tamanho-${item.grade.tamanho}`,
          `status-${item.status_defeito.value}`,
          `direcionamento-${item.direcionamento.value}`,
          ...item.defeitos.map((defeito, index) => `${defeito.value}-${item.localizacao_defeito[index].value}`)
        ]
          .sort()
          .join(',')
      )
    );

    const formCombination = [
      `tamanho-${formObj.grade.tamanho}`,
      `status-${formObj.status_defeito.value}`,
      `direcionamento-${formObj.direcionamento.value}`,
      ...formObj.defeitos.map((defeito, index) => `${defeito.value}-${formObj.localizacao_defeito[index].value}`)
    ]
      .sort()
      .join(',');

    return combinacoesExistentes.has(formCombination);
  }

  const handleChecarLocalizacao = (opt) => {
    if (!defeitoSelecionado) return false;

    const defeitoExists = formObj.defeitos.some(d => d.value === defeitoSelecionado.value);
    const localizacaoExists = formObj.localizacao_defeito.some(ld => ld.value === opt.value);

    return defeitoExists && localizacaoExists;
  }

  const adicionarDefeito = () => {
    if (!defeitoSelecionado || !locDefeitoSelecionado) return;
    formObj.defeitos.push(defeitoSelecionado);
    formObj.localizacao_defeito.push(locDefeitoSelecionado);
    setFormObj({ ...formObj });
    setDefeitoSelecionado(null);
    setLocDefeitoSelecionado(null);
  }

  useEffect(() => {
    setFormObj({
      grade: null, defeitos: [], status_defeito: null, localizacao_defeito: [], direcionamento: null, quantidade: null, imagens: []
    });
  }, [modalDirecionamento]);

  return (
    <Modal
      show={modalDirecionamento}
      backdrop={'static'}
      keyboard={false}
      size={'xl'}
      onHide={closeModal}
      centered={true}
      backdropClassName={styles.backdrop_z_override}
      fullscreen={'lg-down'}
    >
      <Modal.Header closeButton style={{ fontWeight: '500' }}>
        Direcionamento Revisão {referenciaSelecionada.nf_segunda_qualidade ? '(Segunda Qualidade)' : ''} | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body}>
        <div className={styles.direcionamento_info}>
          Quantidade de Peças na Amostra: <span className={styles.font_blue_big}>{referenciaSelecionada.qtde_pecas_amostra}</span> | Quantidade Total de Peças Direcionadas: <span className={styles.font_blue_big}>{quantidadeTotalDirecionada}</span>
        </div>
        <div
          className={styles.direcionamento_info_warn}
          style={{ display: referenciaSelecionada.qtde_pecas_amostra === quantidadeTotalDirecionada ? 'block' : 'none' }}
        >
          Você chegou ao limite de peças da amostra.
        </div>
        <div
          className={styles.direcionamento_info_warn}
          style={{ display: precoTagDivergente.key || composicaoTagDivergente.key ? 'block' : 'none' }}
        >
          Você indicou que há divergências entre as informações da TAG e do produto - todos os direcionamentos serão automaticamente alterados para DEVOLUÇÃO durante o processamento.
        </div>
        <Form className={styles.defeitos_form}>
          <section>
            <label className={styles.form_label}>Selecionar Tamanho</label>
            <ListGroup className={styles.list_group}>
              {gradeRevisao.map((item, index) => {
                return (
                  <ListGroup.Item
                    className={styles.list_item}
                    key={item.tamanho}
                    action={true}
                    active={formObj.grade && formObj.grade.tamanho === item.tamanho}
                    onClick={e => { e.preventDefault(); handleSetFormObj('grade', { ...item, index }); }}
                    disabled={parseInt(item.quantidade_aprovada_amostra) === 0 || item.sem_defeitos}
                    variant={
                      parseInt(item.quantidade_aprovada) === 0 ? 'warning' : item.sem_defeitos ? 'secondary' : 'primary'
                    }
                  >
                    <div style={{ textAlign: 'center' }}>
                      [{item.tamanho}] - Sem Defeito: {item.quantidade_aprovada}<br />
                      Restam na Amostra: {item.quantidade_aprovada_amostra}
                    </div>
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Defeito'}
              style={{ width: '33%' }}
              value={defeitoSelecionado}
              onChange={e => { setDefeitoSelecionado(e); setLocDefeitoSelecionado(null); }}
              options={listaDefeitos}
              filterOption={customFilter}
              isDisabled={!formObj.grade}
            />

            <SelectInput
              label={'Localização Defeito'}
              style={{ width: '33%' }}
              value={locDefeitoSelecionado}
              onChange={e => setLocDefeitoSelecionado(e)}
              options={listaLocalizacaoDefeito}
              filterOption={customFilter}
              isDisabled={!defeitoSelecionado || !formObj.grade}
              isOptionDisabled={opt => handleChecarLocalizacao(opt)}
            />

            <Button
              className={styles.btn_footer}
              variant={'outline-success'}
              style={{ width: '33%', fontSize: '13px', height: '36px', fontWeight: 500, alignSelf: 'flex-end' }}
              onClick={adicionarDefeito}
              disabled={!defeitoSelecionado || !locDefeitoSelecionado}
            >
              Adicionar Defeito
            </Button>
          </section>

          <section className={styles.flex_section_column}>
            <Form.Label className={styles.form_label}>Defeitos Inseridos</Form.Label>
            <div className={styles.defeitos_inseridos}>
              {
                formObj.defeitos.length === 0 ? <div className={styles.defeitos_inseridos_warning}>Não há defeitos inseridos</div> :
                  formObj.defeitos.map((item, index) => {
                    return (
                      <div key={index} className={styles.defeitos_inseridos_item}>
                        <div className={styles.defeitos_inseridos_text}>{formObj.defeitos[index].label}</div>
                        <div className={styles.defeitos_inseridos_text_separator}>|</div>
                        <div className={styles.defeitos_inseridos_text}>{formObj.localizacao_defeito[index].label}</div>
                        <div className={styles.defeito_remove_btn} onClick={() => removerDirecionamento(index)}>
                          <MaterialIcon
                            icon={'cancel'}
                            size={20}
                            color='#DC3545'
                          />
                        </div>
                      </div>
                    );
                  })
              }
            </div>
          </section>

          <section className={styles.flex_section}>
            <SelectInput
              label={'Status Defeito'}
              style={{ width: '33%' }}
              value={formObj.status_defeito}
              onChange={e => handleSetFormObjStatusDefeito(e)}
              options={listaStatusDefeito}
              filterOption={customFilter}
              isDisabled={!formObj.grade}
            />

            <Form.Group className={styles.select_group} style={{ width: '33%' }}>
              <Form.Label className={styles.form_label}>Quantidade Direcionada</Form.Label>
              <Form.Control
                type={'text'}
                placeholder={'0'}
                style={{ width: '100%', height: '38px' }}
                value={formObj.quantidade || 0}
                onChange={e => handleSetFormObjQtde(e.target.value)}
                disabled={!formObj.grade || referenciaSelecionada.qtde_pecas_amostra === quantidadeTotalDirecionada}
              />
            </Form.Group>

            <SelectInput
              label={'Direcionamento'}
              style={{ width: '33%' }}
              value={formObj.direcionamento}
              onChange={e => handleSetFormObj('direcionamento', e)}
              options={listaDirecionamentos}
              isDisabled={!formObj.status_defeito || formObj.status_defeito.value === 8 || !formObj.grade}
            />
          </section>
          <section className={styles.flex_section}>
            <div style={{ width: '33%' }}>
              {
                <ListarImagemPreview
                  imagens={formObj.imagens}
                  removerImagem={removerImagem}
                />
              }
            </div>
            <div style={{ width: '33%' }}>
              <input
                type="file"
                accept="image/*"
                id="imageInput"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              <Button
                variant="secondary"
                className={styles.btn_footer}
                style={{ width: '100%', fontSize: '13px', height: '36px', fontWeight: 500 }}
                onClick={() => document.getElementById('imageInput').click()}
              >
                Adicionar Imagens
              </Button>
            </div>

            <Button
              className={styles.btn_footer}
              variant={'success'}
              style={{ width: '33%', fontSize: '13px', height: '36px', fontWeight: 500 }}
              onClick={validarDirecionamento}
            >
              Inserir Direcionamento
            </Button>
          </section>
        </Form>

        <section>
          <label className={styles.form_label}>Peças Defeituosas Direcionadas</label>
          <Tabela
            columns={direcionamento_columns}
            data={direcionamentoRevisao}
            pagination={false}
          />
        </section>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Button
          variant={'warning'}
          className={styles.footer_btn_processamento}
          onClick={closeModal}
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoDirecionamento;
