import React, { useCallback } from "react";

import { useState } from "react";
import { useEffect, useRef } from "react";
import { api } from "../services/api";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';

import { formatarDataBR } from '../utils/dateUtils'

import { Container, Row, Col } from 'react-bootstrap';

// import styles from '../../../styles/PA/ADM/OrientacaoConserto.module.scss';
import styles2 from '../styles/PA/ADM/OrientacaoConserto.module.scss';

import styles from '../styles/Revisao.module.scss';
import { toast, ToastContainer } from "react-toastify";

import MaterialIcon from 'material-icons-react';

import RadioButtonList from "../components/Utils/RadioButtonList";
import { toUTCDDMMYYYY } from "../utils/dateUtils"
import CookiesService from "../services/cookies";

const TRow = (props) => {

  function clickEdit() {
    props.EditHandler();
  }

  function clickAprove() {
    props.AproveHandler();
  }

  function verifyCredentials() {
    if (props.fields[0] === 'TOP SAMPLE' && props.fields[8] !== null) return false;
    if ((props.edited || !(props.tipo !== 2))) return true
  }

  return (
    <tr className={(props.selected ? "bg-primary text-light" : "")}>
      <td className="ps-3 pe-2">
        <Button onClick={clickEdit} className="border-0" variant={"outline-light"}>
          <MaterialIcon icon="edit" size='small' color={"#0d6efd"} />
        </Button>
        <span onClick={() => {
          if (props.fields[8] !== null) {
            toast.warning("Não e possivel receber o mesmo item duas vezes!");
            return
          }
        }}>
          <Button
            disabled={!verifyCredentials()}
            onClick={clickAprove}
            className="border-0"
            variant="outline-light"
          >
            <MaterialIcon
              icon="thumb_up"
              size="small"
              color={props.tipo === 2 ? "#458B18" : "#557342"}
            />
          </Button>
        </span>
      </td>
      <td className="ps-3 pe-3">
        {props.fields[0]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[1]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[2]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[3]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[4]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[7]}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[9]}
      </td>
      <td className="ps-3 pe-3">
        {props.peca && props.peca}
      </td>
      <td className="ps-3 pe-3">
        {props.fields[10]}
      </td>
      <td className="ps-3 pe-5">
        {props.fields[12]}
      </td>
      <td className="ps-3 pe-5">
        {formatarDataBR(props.fields[22])}
      </td>
    </tr>
  )
}

const LaboratorioMonitor = (props) => {

  // const [tipoAnalise, setTipoAnalise] = useState(1);
  // const [pedidoList, setPedidoList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLenght, setPageLength] = useState([1]);

  const [material, setMaterial] = useState('');
  const [cormaterial, setCormaterial] = useState('');
  const [pedido, setPedido] = useState('');

  const [aproveObj, setAproveObj] = useState(false);

  const [consideracoes, setConsideracoes] = useState('');
  const [respEntrega, setRespEntrega] = useState('');

  const { updateTipoAnalise, getTipoAnalise, pedidoList, updatePedidoList } = props

  const EDITAR_TIPO_ANALISE = getTipoAnalise() == 1 ? false : true

  useEffect(() => {
    const lenght = !!pedidoList ? Math.ceil(pedidoList.length / 50) : 1;
    let pLenght = Array.from('0'.repeat(lenght));
    pLenght = pLenght.map((v, i) => {
      return (i);
    })
    setPage(1);
    setPageLength(pLenght);
  }, [pedidoList]);

  const buscarFn = async () => {

    const paramObj = { tipo: getTipoAnalise() };

    if (!!material) {
      paramObj['material'] = material;
    }

    if (!!cormaterial) {
      paramObj['cormaterial'] = cormaterial;
    }

    if (!!pedido) {
      paramObj['pedido'] = pedido;
    }

    const prom = api.get('/lab-monitorar/buscar', {
      params: paramObj
    });
    toast.promise(prom, {
      pending: "Carregando...",
      error: "Erro: Não foi possível carregar."
    })
    const { data } = await prom;
    updatePedidoList(data.data);
  }

  function AproveFn(index) {

    const indx = 50 * (page - 1) + index;

    setAproveObj(pedidoList[indx]);
  }

  async function finishAproveTP() {
    const userLogin = CookiesService.getUserLogin() || 'default';
    const topSample = { ...aproveObj, consideracoes, respEntrega, user: userLogin };


    if (!respEntrega || !userLogin) {
      toast.warning("Há campos obrigatorios em branco!");
      return;
    }

    const nDate = new Date(topSample['DataUltimaAlteracao']).getTime() + (3600000 * 3);

    topSample['DataUltimaAlteracao'] = new Date(nDate);

    const year = topSample['DataUltimaAlteracao'].getFullYear();
    let month = topSample['DataUltimaAlteracao'].getMonth() + 1;
    let day = topSample['DataUltimaAlteracao'].getDate();

    month = month.toString().lenght < 2 ? `0${month}` : `${month}`;
    day = day.toString().lenght < 2 ? `0${day}` : `${day}`;

    topSample['DataUltimaAlteracao'] = `${year}/${month}/${day}`;

    const prom = api.post('/lab-monitorar/aprovar_sample', topSample);
    toast.promise(prom, {
      pending: "Aprovando...",
      error: "Erro: Não foi possível completar seu pedido."
    })

    const { data } = await prom;
    setAproveObj(false);
  }

  async function atualizaTopSample() {
    try {
      await api.get('/lab-monitorar/AtualizaTopSample');
      toast.success("'TOP SAMPLE' Atualizada");
      buscarFn();
    } catch (e) {
      toast.warning(e.message);
    }
  }

  return (
    <div className={"container mt-4 " + props.className}>
      <h3>Laboratorio: Monitorar</h3>
      <div className="mt-4">

        <Modal show={!!aproveObj}>
          <Modal.Header>
            <div className="d-flex justify-content-between w-100">
              <h3 className="ms-2">Receber TOP SAMPLE</h3>
              <Button variant="outline-primary" onClick={() => {
                setAproveObj(false);
                setConsideracoes('');
                setRespEntrega('');
              }}>X</Button>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex justify-content-between w-100 ps-1 pe-1">
              <Form.Label className="w-100 d-flex flex-column">
                Empresa: <span className="text-primary">{aproveObj['Empresa']}</span>
              </Form.Label>
              <Form.Label className="w-100 d-flex flex-column">
                Pedido: <span className="text-primary">{aproveObj['Pedido']}</span>
              </Form.Label>
            </div>
            <div className="d-flex justify-content-between w-100 ps-1 pe-1">
              <Form.Label className="w-100 d-flex flex-column">
                Material: <span className="text-primary">{aproveObj['Material']}</span>
              </Form.Label>
              <Form.Label className="w-100 d-flex flex-column">
                Cod. Fornecedor: <span className="text-primary">{aproveObj['CodigoFornecedor']}</span>
              </Form.Label>
            </div>
            <div className="d-flex justify-content-between w-100 ps-1 pe-1">
              <Form.Label className="w-100 d-flex flex-column">
                Desc. Material: <span className="text-primary">{aproveObj['DescricaoMaterial']}</span>
              </Form.Label>
              <Form.Label className="w-100 d-flex flex-column">
                Fornecedor: <span className="text-primary">{aproveObj['NomeCliFor']}</span>
              </Form.Label>
            </div>

            <div className="d-flex justify-content-between w-100 ps-1 pe-1">
              <Form.Label className="w-100 d-flex flex-column">
                Dt. Ultima Alteracao: <span className="text-primary">{new Date(aproveObj['DataUltimaAlteracao']).toLocaleString()}</span>
              </Form.Label>
              <Form.Label className="w-100 d-flex flex-column">
                Cor: <span className="text-primary">{aproveObj['CorMaterial']}</span>
              </Form.Label>
            </div>
            <div className="d-flex justify-content-between w-100 ps-1 pe-1">
              <Form.Label className="w-100 d-flex flex-column">
                Qtde Entrada: <span className="text-primary">{aproveObj['QtdeEntrada']}</span>
              </Form.Label>
              <Form.Label className="w-100 d-flex flex-column">
                Desc. Cor: <span className="text-primary">{aproveObj['DescricaoCor']}</span>
              </Form.Label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex flex-column justify-content-between w-100 ">
              <div className="d-flex flex-column no-wrap text-nowrap">
                <Form.Label className="me-3">Considerações:</Form.Label>
                <Form.Control value={consideracoes} onChange={(e) => setConsideracoes(e.target.value)} className="me-3" />
              </div>
              <div className="d-flex flex-row no-wrap text-nowrap align-items-center mt-3">
                <Form.Label className="me-3">Responsável Entrega:</Form.Label>
                <Form.Control value={respEntrega} onChange={(e) => setRespEntrega(e.target.value)} className="me-3" />
                <Button onClick={finishAproveTP} variant="outline-primary">Receber</Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>

        <Form.Group className="d-flex flex-row">
          <Form.Label className="me-3">Tipo de análise:</Form.Label>
          <Form.Check className="me-3" checked={getTipoAnalise() === 1} onChange={(e) => updateTipoAnalise(1)} type="radio" label="Producao" />
          <Form.Check className="me-3" checked={getTipoAnalise() === 2} onChange={(e) => updateTipoAnalise(2)} type="radio" label="Top Sample" />
          {/* <Form.Check className="me-3" checked={tipoAnalise === 3} onChange={(e) => setTipoAnalise(3)} type="radio" label="Teste Base"/> */}
        </Form.Group>

        <Form.Group className="d-flex flex-row mt-4">
          <div className="d-flex flex-column me-3">
            <Form.Label>Pedido:</Form.Label>
            <Form.Control onChange={e => { setPedido(e.target.value) }} />
          </div>
          <div className="d-flex flex-column me-3">
            <Form.Label>Material:</Form.Label>
            <Form.Control onChange={e => { setMaterial(e.target.value) }} />
          </div>
          <div className="d-flex flex-column me-4">
            <Form.Label>Cor:</Form.Label>
            <Form.Control onChange={e => { setCormaterial(e.target.value) }} />
          </div>
          <div className="d-flex flex-column">
            <Form.Label className="opacity-0">Cor:</Form.Label>
            <div className="d-flex flex-row ">
              <Button className="me-3" onClick={buscarFn}>Buscar</Button>
              <Button className="d-flex flex-row align-items-center" variant={"outline-light"} disabled={!(getTipoAnalise() === 2)} onClick={atualizaTopSample}>
                <MaterialIcon icon="replay" size='small' color={"#0d6efd"} />
                <span className="ms-2 text-primary">Atualizar Base TOP SAMPLE</span>
              </Button>
            </div>
          </div>
        </Form.Group>

      </div>
      <div className="mt-4 border overflow-auto bg-light">
        <div className={styles.list}>
          <Table className={styles.tableGrayMargin}>
            <thead className="text-nowrap">
              <tr>
                <th className="ps-3 pe-3">

                </th>
                <th className="ps-3 pe-3">
                  TIPO AGENDAMENTO
                </th>
                <th className="ps-3 pe-3">
                  DATA AGENDAMENTO
                </th>
                <th className="ps-3 pe-3">
                  ID USUARIO
                </th>
                <th className="ps-3 pe-3">
                  MATERIAL
                </th>
                <th className="ps-3 pe-3">
                  DESCRIÇÃO MATERIAL
                </th>
                <th className="ps-3 pe-3">
                  COR MATERIAL
                </th>
                <th className="ps-3 pe-3">
                  DESCRIÇÃO COR
                </th>
                <th className="ps-3 pe-3">
                  PECAS
                </th>
                <th className="ps-3 pe-3">
                  QTDE TESTES PENDENTES
                </th>
                <th className="ps-3 pe-5">
                  NOME CLIFOR
                </th>
                <th className="ps-3 pe-5">
                  DATA ALTERAÇAO PEDIDO
                </th>
              </tr>
            </thead>
            <tbody className="text-nowrap">
              {!!pedidoList ? pedidoList.slice(50 * (page - 1), 50 * (page - 1) + 50).map((p, i) => {
                return <TRow
                  edited={EDITAR_TIPO_ANALISE}
                  EditHandler={() => props.selectHandler(50 * (page - 1) + i, pedidoList[i])}
                  AproveHandler={() => AproveFn(i)}
                  key={i}
                  peca={pedidoList[i].Peca}
                  tipo={getTipoAnalise()}
                  selected={false}
                  fields={Object.values(p)}
                />
              }) : []}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-end mt-3">
        <Form.Label className="me-3">Page: </Form.Label>
        <div>
          <Form.Select onChange={(e) => setPage(e.target.value)}>
            {
              pageLenght.map((v) => {
                return <option key={v + 1} value={v + 1}>{v + 1}</option>
              })
            }
          </Form.Select>
        </div>
      </div>
    </div>
  );
};

const LaboratorioEdit = (props) => {

  const [tipoEnvio, setTipoEnvio] = useState({
    estilo: false,
    produto: false,
    any: false
  });
  const { objSelecionado } = props;
  const [listTestesPendentes, setListTestesPendendes] = useState([]);
  const [listStatus, setListStatus] = useState([]);
  const [listIndices, setListIndices] = useState([]);
  const [listTestesPendendesSelecionado, setListTestesPendendesSelecionado] = useState('');
  const [formObj, setFormObj] = useState({});
  const [qtdPecas, setQtdPecas] = useState('');
  const [difRendFornecedorCQ, setDifRendFornecedorCQ] = useState('');
  const [difMtsPorPeca, setDifMtsPorPeca] = useState('');
  const [statusReetiquetagem, setStatusReetiquetagem] = useState('');
  const [difRendimento, setDifRendimento] = useState('');
  const [gradeTestes, setGradeTestes] = useState([]);
  const [obs, setObs] = useState('');
  const [gramaturaFornecedor, setGramaturaFornecedor] = useState('');
  const [larguraFornecedor, setLarguraFornecedor] = useState('');
  const [gramaturaEncontrada, setGramaturaEncontrada] = useState('');
  const [larguraEncontrada, setLargurEncontrada] = useState('');
  const [atualizarEntradaFiscal, setAtualizarEntradaFiscal] = useState('');
  const [listMovitoRecusa, setListMovitoRecusa] = useState([])

  let selectList = useRef();

  const paramObj = {
    TipoAgendamento: objSelecionado.TipoAgendamento,
    Peca: objSelecionado.Peca,
    Pedido: objSelecionado.Pedido,
    Material: objSelecionado.Material,
    CorMaterial: objSelecionado.CorMaterial,
    Fornecedor: objSelecionado.NomeCliFor,
    DataUltimaAlteracao: objSelecionado.DataUltimaAlteracao

  };

  useEffect(() => {
    fetchListaPendentes();
    fetchListaStatus();
    fetchListaMotivoRecusa();
    setGradeTestes([]);
  }, [objSelecionado]);


  async function fetchListaMotivoRecusa() {
    setListTestesPendendes([]);
    try {
      const lista = await api.get('/lab-monitorar/MotivoRecusa', { params: paramObj });
      if (lista.data.length > 0) {
        const listaData = lista.data.map((item, index) => {
          return { value: item.value, label: item.label, checked: false };
        });
        setListMovitoRecusa(listaData);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function fetchListaPendentes() {
    setListTestesPendendes([]);
    try {
      const lista = await api.get('/lab-monitorar/testesPendentes', { params: paramObj });
      if (lista.data.length > 0) {
        const listaData = lista.data.map((item, index) => {
          return { value: item.value, label: item.label, checked: false };
        });
        setListTestesPendendes(listaData);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function fetchListaIndices(idTeste) {

    setListIndices([]);
    try {
      const lista = await api.get('/lab-monitorar/listIndices', { params: { TestesPendentes: idTeste } });
      if (lista.data.length > 0) {
        const listaData = lista.data.map((item, index) => {
          return { value: item.value, label: item.label, checked: false };
        });
        setListIndices(listaData);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
    handleCalculaMenorLargura(idTeste);
  }

  async function handleCalculaMenorLargura(idTeste) {

    // if(idTeste != 7) return false;

    const paramObj = {
      NFEntrada: objSelecionado.NFEntrada,
      SerieNF: objSelecionado.SerieNF,
      NomeCliFor: objSelecionado.NomeCliFor,
      Material: objSelecionado.Material,
      CorMaterial: objSelecionado.CorMaterial,
      Item: objSelecionado.Item

    };

    try {
      const lista = await api.get('/lab-monitorar/MenorLargura', { params: paramObj });
      if (lista.data.length > 0) {

        const listaData = lista.data;
        setQtdPecas(listaData[0].QtdePeca);
        setLarguraFornecedor(listaData[0].LarguraFornecedor);
        setLargurEncontrada(listaData[0].MenorLauguraUtil);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  async function fetchListaStatus() {
    setListStatus([]);
    try {
      const lista = await api.get('/lab-monitorar/listStatus');
      if (lista.data.length > 0) {
        const listaData = lista.data.map((item, index) => {
          return { value: item.value, label: item.label, checked: false };
        });
        setListStatus(listaData);
      }
    } catch (error) {
      toast.error('Ocorreu um erro para carregar lista de status!');
      return false;
    }
  }

  function CalcularRendimentoFornecedor(gramatura, largura) {
    if (parseFloat(gramatura) != NaN && parseFloat(largura) != NaN) {
      let x = (1000 / (parseFloat(gramatura) * parseFloat(largura) / 100)).toFixed(2)
      let y = (objSelecionado.QtdeEntrada / objSelecionado.FatorConversaoUnidade)
      setFormObj({ ...formObj, rendimentoFornecedor: (x * y).toFixed(2) || '', rendimentoKGFornecedor: x });
      RegraRendimento();
    }
  }

  function CalcularRendimentoEncontrado(gramatura, largura) {

    if (parseFloat(gramatura) != NaN && parseFloat(largura) != NaN) {
      let x = 1000 / ((parseFloat(gramatura) * parseFloat(largura)) / 100);
      let y = (x * (objSelecionado.QtdeEntrada / objSelecionado.FatorConversaoUnidade)).toFixed(2)

      setFormObj({ ...formObj, rendimentoEncontrada: y || '', rendimentoKGEncontrada: x.toFixed(2) });
      setDifRendimento(objSelecionado.FatorConversaoUnidade - formObj?.rendimentoKGFornecedor);

      RegraRendimento();

    }
  }

  function clearFields() {
    setGramaturaFornecedor('');
    setLarguraFornecedor('');
    setGramaturaEncontrada('');
    setLargurEncontrada('');
    setAtualizarEntradaFiscal('');

    setFormObj({})
  }

  function RegraRendimento() {
    if (formObj.rendimentoEncontrada != '' && formObj.rendimentoFornecedor != '') {
      let sub = (formObj.rendimentoFornecedor - formObj.rendimentoEncontrada)
      let difMtsPeca = (sub / parseFloat(qtdPecas, 2))

      setDifMtsPorPeca(difMtsPeca.toFixed(2));
      const difRendimento = (formObj.rendimentoFornecedor - formObj.rendimentoEncontrada).toFixed(2)

      setDifRendFornecedorCQ(difRendimento, formObj.rendimentoFornecedor, formObj.rendimentoEncontrada);
      if (difMtsPeca >= 1) {
        setStatusReetiquetagem('REETIQUETAR');
      } else {
        setStatusReetiquetagem('NÃO REETIQUETAR')
      }

      if ((Math.round((formObj.rendimentoFornecedor - formObj.rendimentoEncontrada), 2) >= 5)) {
        setAtualizarEntradaFiscal('SIM');
      } else {
        setAtualizarEntradaFiscal('NÂO');
      }
    }

  }

  const handleIncluiTeste = async () => {
    if (formObj.testePendenteSelecionado == null) {
      toast.error('Favor informar o TESTE antes de continuar!');
      return false;
    }
    if (formObj.indiceSelecionado.value == null) {
      toast.error('Favor informar selecionar o ÍNDICE antes de continuar!');
      return false;
    }
    if (formObj.statusSelecionado == null) {
      toast.error('Favor informar selecionar o STATUS antes de continuar!');
      return false;
    }
    if (formObj.testePendenteSelecionado == 7 && gramaturaFornecedor == null) {
      toast.error('Favor informar a GRAMATURA do FORNEDOR!');
      return false;
    }
    if (formObj.testePendenteSelecionado == 7 && larguraFornecedor == null) {
      toast.error('Favor informar a LARGURA do FORNEDOR!');
      return false;
    }
    if (formObj.testePendenteSelecionado == 7 && larguraEncontrada == null) {
      toast.error('Favor informar a LARGURA encontrada!');
      return false;
    }

    const itemParaInserir = {
      teste: formObj.testePendenteSelecionado,
      indice: formObj.indiceSelecionado,
      status: formObj.statusSelecionado,
      gramaturaFornecedor: gramaturaFornecedor,
      larguraFornecedor: larguraFornecedor ? larguraFornecedor : (isGramatura() ? formObj.objSelecionado.larguraFornecedor : ''),
      gramaturaEncontrada: gramaturaEncontrada,
      larguraEncontrada: larguraEncontrada ? larguraEncontrada : (isGramatura() ? formObj.objSelecionado.larguraEncontrada : ''),
      qtdPecas: qtdPecas,
      obs: obs,
    };

    let grade = [];
    grade = grade.concat(gradeTestes);
    grade.push(itemParaInserir);
    setGradeTestes(grade);

    setListTestesPendendes(listTestesPendentes.filter(el => el.value !== itemParaInserir.teste.value));

    //falta limpar o form
    setFormObj({
      ...formObj,
      testePendenteSelecionado: null,
      indiceSelecionado: null,
      statusSelecionado: null
    });
    setObs('');
    selectList.current.clearChecks();
    toast.success('Teste incluído na grade');
    clearFields()
  }

  const handleProcess = async (e, cb) => {
    e.preventDefault();

    let confirmationMessage = "O processo está sendo finalizado com pendenciamento em ";
    if (tipoEnvio.estilo) {
      confirmationMessage += "ESTILO";
    } else if (tipoEnvio.produto) {
      confirmationMessage += "PRODUTO";
    } else if (tipoEnvio.any) {
      confirmationMessage += "NÃO";
    } else {
      confirmationMessage += "NENHUM";
    }
    confirmationMessage += ". Deseja confirmar?";
  
    if (!window.confirm(confirmationMessage)) {
      return;
    }

    if (!window.confirm(confirmationMessage)) {
      return;
    }

    if (gradeTestes.length < 1) {
      toast.error('Favor incluir avaliações de prosseguir!');
      return false;
    }
    if (atualizarEntradaFiscal == 'SIM') {
      toast.error('Atenção! Existe diferença no fator de conversão! Clicar no Botão Atualizar Entrada Fiscal antes de Continuar!');
      return false;
    }

    if (!tipoEnvio.estilo && !tipoEnvio.produto && !tipoEnvio.any) {
      toast.error('Favor informar se o material seguirá ou não para aprovação');
      return false;
    }

    if (objSelecionado.TipoAgendamento == 'TOP SAMPLE' && formObj.validarPadrao == null) {
      toast.error('Atenção! Favor realizar a validação do Padrão!');
      return false;
    }

    if (objSelecionado.TipoAgendamento == 'TOP SAMPLE' && formObj.validarPadrao == 2 && formObj.motivoRecusaSelecionada == null) {
      toast.error('Atenção! Favor informar o motivo da recusa!');
      return false;
    }

    const params = {
      NFEntrada: objSelecionado.NFEntrada,
      SerieNF: objSelecionado.SerieNF,
      NomeCliFor: objSelecionado.NomeCliFor,
      Material: objSelecionado.Material,
      CorMaterial: objSelecionado.CorMaterial,
      Item: objSelecionado.Item,
      Grade: gradeTestes,
      TipoEnvio: tipoEnvio,
      TipoAgendamento: objSelecionado.TipoAgendamento,
      IndicaReetiquetagem: statusReetiquetagem,
      user: CookiesService.getUserLogin(),
      Peca: objSelecionado.Peca,
      DataUltimaAlteracao: objSelecionado.DataUltimaAlteracao,
      Pedido: objSelecionado.Pedido,
      QtdeEntrada: objSelecionado.QtdeEntrada,
      ValidarPadrao: formObj.validarPadrao,
      MotivoRecusa: formObj.motivoRecusaSelecionada,
    };

    await api.post("/lab-monitorar/Processar", params)
      .then(res => {
        if (res.status === 200) {
          toast.success('Dados alterados com sucesso!');
          setFormObj({});
          selectList.current.clearChecks();
          props.closeHandler();
          setTipoEnvio({
            estilo: false,
            produto: false,
            any: false
          })

          cb()

          // pedidoList.filter
          //window.location.href = "/PA/monitorchecklist";
        } else {
          toast.error(`Erro, status code: "${res.status}". Entre em contato com o suporte.`);
        }
      })
      .catch(e => {
        console.error(e);
        toast.error(`Ocorreu um erro, por favor entre em contato com o suporte.`)
      });
  }

  async function updateEntradaFiscal() {
    const object = { ...formObj, ...objSelecionado, larguraFornecedor, gramaturaFornecedor };
    try {
      const request = await api.post("/lab-monitorar/AtualizarEntradaFiscal", object);
      if (request.status === 200 || request.status === 204) {
        toast.success('Entrada Fiscal Atualizada !')
      }

      if (request.status === 207) {
        if (request && request.data) toast.warning(request.data.message.toLowerCase())
      }

    } catch (error) {
      toast.error('Erro ao tentar atualizar entrada fiscal !');
    }
  }

  function checkIfDisabled(type) {
    if (gradeTestes.every(test => test.status.value === 4) && type === 1) return true;
    if (gradeTestes.some(test => test.status.value === 8) && type === 2) return true;
    return false;
  }

  function EnvioCheck(number) {
    if (number === 3) {
      setTipoEnvio({
        estilo: false,
        produto: false,
        any: true
      })
    }

    if (number === 1) {
      setTipoEnvio({
        ...tipoEnvio,
        estilo: !tipoEnvio.estilo
      })
    }

    if (number === 2) {
      setTipoEnvio({
        ...tipoEnvio,
        any: false,
        produto: !tipoEnvio.produto
      })
    }
  }

  function isGramatura() {
    return formObj && formObj?.testePendenteSelecionado?.value === 7
  }

  useEffect(() => {
    if (isGramatura()) CalcularRendimentoFornecedor()
  }, [formObj.testePendenteSelecionado])

  return (
    <div className={"container mt-4 " + props.className}>
      <h3>Resultado Testes</h3>
      <div className="mt-4">

        <h5>Tipo Laudo: <span className="text-primary">{objSelecionado.TipoAgendamento}</span></h5>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Entrada Fiscal</Accordion.Header>
            <Accordion.Body>
              <div className={styles2.gridRow}>
                <div className={styles2.gridItem}>
                  <span>Empresa:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Empresa}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Pedido:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Pedido}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Série:</span>
                  <span className={styles2.fontBlue}>{objSelecionado.SerieNF}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Quantidade Entrada:</span>
                  <span className={styles2.fontBlue}>{objSelecionado.QtdeEntrada}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>NF entrada:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.NFEntrada}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Data de Alteração:</span>
                  <span className={styles2.fontBlue}>{toUTCDDMMYYYY(objSelecionado?.DataUltimaAlteracao)}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Fornecedor:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.NomeCliFor}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Data de Agendamento:</span>
                  <span className={styles2.fontBlue}>{toUTCDDMMYYYY(objSelecionado?.DataAgendamento)}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Marca:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Marca}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Tipos:</span>
                  <span className={styles2.fontBlue}>{objSelecionado.Tipo}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Composição:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Composicao}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Material:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Material}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Descrição:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.DescricaoMaterial}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Cor Marterial:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.CorMaterial}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Descrição Cor:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.DescricaoCor}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Requerido por:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.RequeridoPor}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Grupo:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Grupo}</span>
                </div>
                <div className={styles2.gridItem}>
                  <span>Item:</span>
                  <span className={styles2.fontBlue}>{objSelecionado?.Item}</span>
                </div>

              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>


        <Form.Group className="d-flex flex-column mt-5 bg-light ps-5 pe-5 pt-5 pb-3 rounded">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column me-3 w-25">
              <Form.Label>Testes Pendentes:</Form.Label>
              {listTestesPendentes.length > 0 ?
                <RadioButtonList options={listTestesPendentes}
                  ref={selectList}
                  changeList={(e) => { setFormObj({ ...formObj, testePendenteSelecionado: e }); fetchListaIndices(e.value); }}
                >
                </RadioButtonList>
                : 'sem informações'
              }
            </div>
            <div className="d-flex flex-column me-3  w-25">
              <Form.Label>Indice:</Form.Label>
              {listIndices.length > 0 ?
                <RadioButtonList options={listIndices}
                  ref={selectList}
                  changeList={(e) => { setFormObj({ ...formObj, indiceSelecionado: e }) }}
                >
                </RadioButtonList>
                : 'Selecione um teste'
              }
            </div>
            <div className="d-flex flex-column me-3  w-25">
              <Form.Label>Status:</Form.Label>
              {listStatus.length > 0 ?
                <RadioButtonList options={listStatus}
                  ref={selectList}
                  changeList={(e) => { setFormObj({ ...formObj, statusSelecionado: e }) }}
                >
                </RadioButtonList>
                : 'sem informações'
              }
            </div>
          </div>

          <div className="d-flex flex-row mt-4">
            <div className="d-flex flex-column me-3 w-50">
              <Form.Label>Observações (Opcional):</Form.Label>
              <Form.Control
                value={obs}
                onChange={(e) => { setObs(e.target.value) }}
              />
            </div>
          </div>
          <div className="d-flex flex-row mt-4 justify-content-between">
            <div className="d-flex flex-column w-100">
              <Form.Label>Gram. Fornecedor:</Form.Label>
              <Form.Control className="w-75" style={{ backgroundColor: '#CCC' }}
                type="number"
                disabled={formObj?.testePendenteSelecionado?.value != 7}
                value={gramaturaFornecedor && gramaturaFornecedor}
                onChange={(e) => {
                  setGramaturaFornecedor(e.target.value);
                  const gramaturaFornecedoraAtualizada = e.target.value;
                  const larguraFornecedoraCalculada = larguraFornecedor || formObj.objSelecionado.larguraFornecedor;

                  CalcularRendimentoFornecedor(
                    gramaturaFornecedoraAtualizada,
                    isGramatura() ? larguraFornecedoraCalculada : 1
                  )
                  RegraRendimento()
                }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Larg. Fornecedor:</Form.Label>
              <Form.Control className="w-75" style={{ backgroundColor: '#CCC' }}
                type="number"
                value={isGramatura() ? (larguraFornecedor || props.objSelecionado.LargFornecedor) : larguraFornecedor}
                onChange={(e) => {
                  setLarguraFornecedor(e.target.value);
                  const larguraFornecedoraAtualizada = e.target.value;
                  setFormObj({ ...formObj, objSelecionado: { larguraFornecedor: larguraFornecedoraAtualizada } })
                  const larguraFornecedoraCalculada = larguraFornecedoraAtualizada || formObj.objSelecionado.larguraFornecedor || 1;

                  CalcularRendimentoFornecedor(
                    gramaturaFornecedor,
                    isGramatura() ? larguraFornecedoraCalculada : 1
                  );
                }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Rend. Fornecedor:</Form.Label>
              <Form.Control className="w-75" style={{ backgroundColor: '#CCC' }}
                type="number"
                disabled={true}
                value={formObj.rendimentoFornecedor ? formObj.rendimentoFornecedor : ''}
                onChange={(e) => { setFormObj({ ...formObj, rendimentoFornecedor: e.target.value }) }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Rend. KG. Fornecedor:</Form.Label>
              <Form.Control className="w-75" style={{ backgroundColor: '#CCC' }}
                type="number"
                disabled={true}
                value={formObj.rendimentoKGFornecedor ? formObj.rendimentoKGFornecedor : ''}
                onChange={(e) => { setFormObj({ ...formObj, rendimentoKGFornecedor: e.target.value }) }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Fator conversão unidade:</Form.Label>
              <Form.Label className="text-primary">{objSelecionado.FatorConversaoUnidade}</Form.Label>
            </div>
          </div>

          <div className="d-flex flex-row mt-4 justify-content-between">
            <div className="d-flex flex-column w-100">
              <Form.Label>Gram. Encontrada:</Form.Label>
              <Form.Control className="w-75"
                type="number"
                disabled={formObj?.testePendenteSelecionado?.value != 7}
                //disabled={atualizarEntradaFiscal != 'SIM'}
                value={gramaturaEncontrada}
                onChange={(e) => { RegraRendimento(); setGramaturaEncontrada(e.target.value); CalcularRendimentoEncontrado(e.target.value, larguraEncontrada ? larguraEncontrada : 1); }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Larg. Encontrada:</Form.Label>
              <Form.Control className="w-75"
                type="number"
                // disabled ={formObj?.testePendenteSelecionado?.value !== 7}
                //disabled={atualizarEntradaFiscal != 'SIM'}
                value={isGramatura() ? (larguraEncontrada || props.objSelecionado.largEncontrada) : larguraEncontrada}
                onChange={(e) => { setLargurEncontrada(e.target.value); CalcularRendimentoEncontrado(gramaturaEncontrada ? gramaturaEncontrada : 1, e.target.value); }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Rend. Encontrada:</Form.Label>
              <Form.Control className="w-75"
                type="number"
                disabled={true}
                value={formObj.rendimentoEncontrada ? formObj.rendimentoEncontrada : ''}
                onChange={(e) => { setFormObj({ ...formObj, rendimentoEncontrada: e.target.value }) }}
              />
            </div>
            <span>rendimento: ${formObj.rendimentoEncontrada} </span>
            <div className="d-flex flex-column w-100">
              <Form.Label>Rend. KG. Encontrada:</Form.Label>
              <Form.Control className="w-75"
                type="number"
                disabled={true}
                value={formObj.rendimentoKGEncontrada ? formObj.rendimentoKGEncontrada : ''}
                onChange={(e) => { setFormObj({ ...formObj, rendimentoKGEncontrada: e.target.value }) }}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <Form.Label>Fator conversão diferença unidade:</Form.Label>
              <Form.Label className="text-primary">{difRendimento}</Form.Label>
            </div>
          </div>

          <div className="d-flex flex-row mt-5 mb-2 me-4">
            <div className="ms-auto">
              <Button className="btn-success"
                onClick={handleIncluiTeste}
              >Incluir Análise</Button>
            </div>
          </div>
        </Form.Group>

        <Form.Group className="d-flex flex-column mt-5 bg-light ps-5 pe-5 pt-5 pb-3 mb-5 rounded">
          <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column me-3 w-100">
              <Form.Label>Testes Incluidos:</Form.Label>
              <div className="d-flex w-100 border rounded ps-4 pe-4 overflow-auto text-nowrap">
                <Table>
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>
                        ID Teste
                      </th>
                      <th>
                        Descrição
                      </th>
                      <th>
                        Indice
                      </th>
                      <th>
                        Status
                      </th>
                      <th>
                        Gram. Forn.
                      </th>
                      <th>
                        Gram. Enc.
                      </th>
                      <th>
                        Larg. Forn.
                      </th>
                      <th>
                        Larg. Enc.
                      </th>
                      <th>
                        Observações
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {gradeTestes?.map((item, index) => {
                      return (
                        <tr key={`rowGradeTeste${index}`}>
                          <td>
                            <Button
                              title="Remover da lista"
                              variant="danger"
                              onClick={(el) => {
                                setGradeTestes(gradeTestes.filter(el => el !== item));
                                setListTestesPendendes(listTestesPendentes.concat([{ value: item.teste.value, label: item.teste.label }]))
                              }}
                            >
                              <i>
                                <MaterialIcon
                                  icon="delete"
                                  size={20}
                                  color='#FFFF'

                                />
                              </i>
                            </Button>
                          </td>
                          <td>
                            {item.teste.value}
                          </td>
                          <td>
                            {item.teste.label}
                          </td>
                          <td>
                            {item.indice.label}
                          </td>
                          <td>
                            {item.status.label}
                          </td>
                          <td>
                            {item.gramaturaFornecedor}
                          </td>
                          <td>
                            {item.gramaturaEncontrada}
                          </td>
                          <td>
                            {formObj && formObj.testePendenteSelecionado == 7 ? item.larguraFornecedor : ''}
                          </td>
                          <td>
                            {formObj && formObj.testePendenteSelecionado == 7 ? item.larguraEncontrada : ''}
                          </td>
                          <td>
                            {item.obs}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>

            </div>
          </div>

          <div className="d-flex flex-row mt-4 justify-content-between">
            <div className="d-flex flex-column w-100 pe-3">
              <Form.Label>Atualizar Entrada Fiscal:</Form.Label>
              <Form.Label className="text-primary">{atualizarEntradaFiscal}</Form.Label>
            </div>
            <div className="d-flex flex-column w-100 ps-3 pe-3">
              <Form.Label>Qtde Peças:</Form.Label>
              <Form.Label className="text-primary">{qtdPecas && qtdPecas}</Form.Label>
            </div>
            <div className="d-flex flex-column w-100 ps-3 pe-3">
              <Form.Label>Dif. Red. Fornecedor X CQ:</Form.Label>
              <Form.Label className="text-primary">{(formObj.rendimentoFornecedor - formObj.rendimentoEncontrada).toFixed(2) || ''}</Form.Label>
            </div>
            <div className="d-flex flex-column w-100 ps-3 pe-3">
              <Form.Label>Dif. Mts Por Peça:</Form.Label>
              <Form.Label className="text-primary">{((formObj.rendimentoFornecedor - formObj.rendimentoEncontrada) / parseFloat(qtdPecas, 2)).toFixed(2) || ''}</Form.Label>
            </div>
            <div className="d-flex flex-column w-100 ps-3">
              <Form.Label>Status Reetiquetagem:</Form.Label>
              <Form.Label className="text-primary">{statusReetiquetagem}</Form.Label>
            </div>
          </div>

          <div className="d-flex flex-row mt-4 justify-content-between">
            <div className="d-flex flex-column ps-3 pe-3 pt-3 pb-3 rounded border">
              <Form.Label>Enviar Validação?</Form.Label>
              <Form.Check
                type="checkbox"
                checked={tipoEnvio.estilo}
                onChange={e => EnvioCheck(1)}
                label="Estilo"
                disabled={checkIfDisabled(1)}
              />
              <Form.Check
                type="checkbox"
                checked={tipoEnvio.produto}
                onChange={e => EnvioCheck(2)}
                label="Produto"
                disabled={checkIfDisabled(1)}
              />
              <Form.Check
                type="checkbox"
                checked={tipoEnvio.any}
                onChange={e => EnvioCheck(3)}
                label="Não"
                disabled={checkIfDisabled(2)}
              />
            </div>
          </div>
          {objSelecionado.TipoAgendamento == 'TOP SAMPLE' ?
            <>
              <div className="d-flex flex-row mt-4 justify-content-between">
                <div className="d-flex flex-column ps-3 pe-3 pt-3 pb-3 rounded border">
                  <Form.Label>Validar Padrão?</Form.Label>
                  <Form.Check type="radio" checked={formObj?.validarPadrao === 1} onChange={e => { setFormObj({ ...formObj, validarPadrao: 1 }) }} label="Aprovado" />
                  <Form.Check type="radio" checked={formObj?.validarPadrao === 2} onChange={e => { setFormObj({ ...formObj, validarPadrao: 2 }) }} label="Reprovado" />
                  <Form.Check type="radio" checked={formObj?.validarPadrao === 3} onChange={e => { setFormObj({ ...formObj, validarPadrao: 3 }) }} label="Sem Padrão" />
                </div>
              </div>
              <div className="d-flex flex-row mt-4 justify-content-between">
                <div className="d-flex flex-column ps-3 pe-3 pt-3 pb-3 rounded border">
                  <Form.Label>Motivo Recusa</Form.Label>
                  {listMovitoRecusa.length > 0 ?
                    <RadioButtonList options={listMovitoRecusa}
                      ref={selectList}
                      changeList={(e) => { setFormObj({ ...formObj, motivoRecusaSelecionada: e }) }}
                    >
                    </RadioButtonList>
                    : 'sem informações'
                  }
                </div>
              </div>
            </>
            : ''
          }

          <div className="d-flex flex-row mt-5 mb-2 me-4">
            <div className="ms-auto">
              <Button className="me-3 btn-light border-primary text-primary" disabled={atualizarEntradaFiscal !== 'SIM'} onClick={updateEntradaFiscal}>Atualizar Entrada Fiscal</Button>
              <Button className="me-3 btn-light border-danger text-danger" onClick={() => props.closeHandler()}>Cancelar</Button>
              <Button onClick={(e) => props.processar(e, handleProcess)}>Processar</Button>
            </div>
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

const LaboratorioMonitorar = (props) => {
  const [pedidoSelecionado, setPedidoSelecionado] = useState('');
  const [objSelecionado, setObjSelecionado] = useState({});
  const [pedidoList, setPedidoList] = useState([]);
  const [tipoAnalise, setTipoAnalise] = useState(1);

  useEffect(() => {
  }, [pedidoSelecionado, objSelecionado])


  const updateTipoAnalise = (tipo) => {
    if (tipo) setTipoAnalise(tipo)
  }

  const getTipoAnalise = () => {
    return tipoAnalise
  }

  const isObjectEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  const limparItemTela = useCallback(() => {
    const novoPedidoList = pedidoList.filter((item) =>
      item.Pedido === pedidoSelecionado.Pedido && item.Material === pedidoSelecionado.Material &&
      item.CorMaterial === pedidoSelecionado.CorMaterial)

    setPedidoList(novoPedidoList)
  }, [pedidoSelecionado])


  useEffect(() => {
    (async () => {
      setPedidoList([])
      const prom = api.get('/lab-monitorar/buscar?tipo=' + tipoAnalise);
      toast.promise(prom, {
        pending: "Carregando...",
        error: "Erro: Não foi possível carregar."
      })
      const { data } = await prom;
      setPedidoList(data.data);
    })()
  }, [tipoAnalise]);


  const processar = (event, fn) => { if (fn) fn(event, limparItemTela) }


  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <LaboratorioMonitor pedidoList={pedidoList} updatePedidoList={setPedidoList} getTipoAnalise={getTipoAnalise} updateTipoAnalise={updateTipoAnalise} selectHandler={(n, obj) => { setPedidoSelecionado(n); setObjSelecionado(obj); }} className={!`${pedidoSelecionado}` ? "" : "collapse"} />
      <LaboratorioEdit processar={processar} objSelecionado={objSelecionado} pedidoSelecionado={pedidoSelecionado} selectHandler={(n) => { setPedidoSelecionado(n); }} closeHandler={() => setPedidoSelecionado('')} className={!!`${pedidoSelecionado}` ? "" : "collapse"} />
    </div>
  )
}

export default LaboratorioMonitorar;