import { Modal, Button } from "react-bootstrap";
import styles from '../pa_revisao.module.scss';
import { usePARevisao } from "../pa_revisao_context";

const PARevisaoModalCancelamento = () => {
  const { modalCancelamento, setModalCancelamento, cancelarRevisao, referenciaSelecionada } = usePARevisao();

  return (
    <Modal
      show={modalCancelamento}
      centered
      size={'lg'}
      onHide={() => setModalCancelamento(false)}
      backdropClassName={styles.backdrop_z_override}
    >
      <Modal.Header style={{ fontWeight: '500' }} closeButton>
        Cancelar Análise | NF: {referenciaSelecionada.nf_entrada} | Produto: {referenciaSelecionada.produto} | Cor: {referenciaSelecionada.cor_produto}
      </Modal.Header>
      <Modal.Body className={styles.modal_body} style={{ fontWeight: '500', fontSize: '16px' }}>
        Tem certeza que deseja descartar todos os dados inseridos na análise até o momento?
        <br/>
        Essa ação é irrevesível.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={'success'}
          onClick={() => setModalCancelamento(false)}
          style={{ margin: '0px auto' }}
          className={styles.footer_btn_processamento}
        >
          Voltar
        </Button>
        <Button
          variant={'danger'}
          onClick={cancelarRevisao}
          style={{ margin: '0px auto' }}
          className={styles.footer_btn_processamento}
        >
          Cancelar Análise
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PARevisaoModalCancelamento;